import { dev } from '$app/environment';
import {
  PUBLIC_SENTRY_DSN,
  PUBLIC_SENTRY_ENVIRONMENT,
} from '$env/static/public';
import { replayIntegration } from '@sentry/sveltekit';
import * as Sentry from '@sentry/sveltekit';
import type { HandleClientError } from '@sveltejs/kit';

if (!dev) {
  Sentry.init({
    dsn: PUBLIC_SENTRY_DSN,
    tracesSampleRate: 1.0,

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,

    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,

    // If you don't want to use Session Replay, just remove the line below:
    integrations: [replayIntegration()],
    environment: PUBLIC_SENTRY_ENVIRONMENT,
  });
}

const localHandleHerror: HandleClientError = ({ error }) =>
  console.error(error);

const sentryHandleError: HandleClientError = async ({ error, event }) => {
  const isSSU = event.route.id && event.route.id.includes('(sign-up)');
  const isContentfulPreview =
    event.route.id && event.route.id.includes('/preview/[entityId]');

  if (!isContentfulPreview) {
    Sentry.captureException(error, {
      tags: {
        'website.app': isSSU ? 'sign_up' : 'site',
        'website.env': 'client',
      },
    });
  }

  console.error(error);
};

export const handleError = dev ? localHandleHerror : sentryHandleError;
